<template>
  <div class="supercolossal">
    <span
      style="
        color: #999;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
      "
      >用于管理邮件中作为云附件发送的超大附件</span
    >
    <!-- 搜索区域 -->
    <a-input
      style="width: 240px"
      v-model="inputValue"
      allow-clear
      placeholder="请输入搜索内容"
      allowClear
      @pressEnter="onSearch"
    >
      <a-icon slot="prefix" type="search" />
    </a-input>
    <!-- 表格区域 -->

    <div class="table-container-attachment">
      <a-spin :spinning="loading">
        <a-table
          :dataSource="dataSource"
          ref="attachmentTableRefs"
          :columns="columns"
          :pagination="false"
          :scroll="{ y: fileListEleMaxHeight || 550 }"
        >
          <template slot="name" slot-scope="text, record">
            <template>
              <span class="name-text click"
                >{{ text }}.{{ record.extensionName }}</span
              >
            </template>
          </template>

          <!-- record.auditStatus--文件审核敏感字段 -->
          <template slot="action" slot-scope="text, record">
            <a-dropdown>
              <a><a-icon type="dash" /></a>
              <a-menu slot="overlay">
                <a-menu-item
                  v-if="record.auditStatus && record.auditStatus == 3"
                  @click="send(record)"
                >
                  <a>邮件发送</a>
                </a-menu-item>
                <a-menu-item
                  v-if="record.auditStatus && record.auditStatus == 3"
                  @click="download(record)"
                >
                  <a>下载</a>
                </a-menu-item>
                <a-menu-item
                  v-if="record.auditStatus && record.auditStatus == 3"
                  @click="saveToDoc(record)"
                >
                  <a>转存到个人文档</a>
                </a-menu-item>
                <a-menu-item @click="del(record)">
                  <a>删除</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </a-table>
      </a-spin>
    </div>
    <div class="loading-spinner-box">
      <div v-if="loading && dataSource.length" class="loading-spinner-ing">
        加载中...
      </div>
      <div v-if="showAllDataLoadedMessage" class="loading-spinner-end">
        已加载完全部数据
      </div>
    </div>

    <!-- 保存确认 -->
    <SaveModal
      ref="SaveModalRef"
      v-if="saveModalShow"
      :visible="saveModalShow"
      type="save"
      :options="saveOptions"
      @close="saveModalShow = false"
      @save="saveOk"
    />
  </div>
</template>

<script>
import {
  publicFormatTime,
  downloadDocFile,
  publicFormatSize,
} from "@/utils/index";
import SaveModal from "@/views/popup/moveModal.vue";
export default {
  name: "supercolossal",
  components: {
    SaveModal,
  },
  data() {
    return {
      saveOptions: {
        checkIdList: [],
      },
      total: 0,
      allDataLoaded: false,
      saveModalShow: false,
      showAllDataLoadedMessage: false,
      dataSource: [],
      loading: false,
      fileListEleMaxHeight: 550,
      inputValue: "",
      columns: [
        {
          width: 380,
          title: "名称",
          dataIndex: "userFileName",
          // key: 'fileName',
          align: "left",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "大小",
          dataIndex: "superFilesize",
          align: "center",
          key: "superFilesize",
        },
        {
          title: "上传时间",
          dataIndex: "superFileCreateTime",
          align: "center",
          key: "superFileCreateTime",
        },
        // {
        //   title: "过期时间",
        //   dataIndex: "fff",
        //   align: "center",
        //   key: "fff",
        // },
        {
          title: "操作",
          dataIndex: "",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  watch: {
    inputValue(newVal) {
      console.log("搜索输入关键词:", newVal);
      if (!newVal.length || newVal == "") {
        this.onClear();
      }
    },
    allDataLoaded(newVal) {
      if (newVal) {
        this.showAllDataLoadedMessage = true; // 显示“已加载完全部数据”消息
        setTimeout(() => {
          this.showAllDataLoadedMessage = false; // 2秒后隐藏该消息
        }, 2000);
      }
    },
  },

  mounted() {},
  computed: {
    token() {
      return this.$store.state.user.token;
    },
  },
  created() {
    this.getSuperFileListFn();
  },

  methods: {
    // 超大附件的邮件发送
    async send(item) {
      console.log("🚀 ~ file: index.vue:84 ~ handleViewShare ~ val:", value);
      if (this.token) {
        try {
          let params = {
            fileName: `${item.userFileName}.${item.extensionName}`,
            fileSize: item.userFileSize,
            downloadUrl: item.resourceUrl,
          };
          window.sessionStorage.setItem(
            "largeFileFromDoc",
            JSON.stringify(params)
          );
          let origin = window.location.origin;
          let url = `${origin}/mail/home?from=bigFiles`;
          window.history.pushState({}, "", url);
        } catch (err) {
          console.log(err);
        }
      }
    },
    // 彻底删除
    async del(item) {
      let _that = this;
      this.$confirm({
        centered: true,
        title: "确认删除",
        content: "文档删除后，将无法使用，确定移至回收站吗?",
        okText: "确认",
        okType: "warning",
        okType: "okType",
        cancelText: "取消",
        async onOk() {
          let params = {
            ids: item.id,
          };
          const res = await _that.$apis.userFileBatchDelete(params);
          _that.$message.success("操作成功");
          setTimeout(() => {
            _that.getSuperFileListFn(_that.inputValue); // 刷新列表
          }, 100);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    // 获取超大附件
    async getSuperFileListFn(val) {
      this.loading = true;
      let params = {
        source: "BIGGER_ATTACH",
        orderByColumn: "createTime",
        isAsc: "desc",
      };
      if (val && val.length) {
        params.fileName = val;
      }
      const res = await this.$apis.getSuperFileList(params);
      this.total = res?.data?.total;
      // if(!res?.data?.list) {

      // }
      console.log("超大附件res--", res.data.list);
      this.dataSource = res?.data?.list.map((item) => {
        return {
          ...item,
          superFilesize: publicFormatSize(item.userFileSize),
          superFileCreateTime: publicFormatTime(item.createTime),
        };
      });
      //   this.dataSource = this.dataSource
      //     .concat(this.dataSource)
      //     .concat(this.dataSource)
      //     .concat(this.dataSource);
      if (
        this.total == this.dataSource.length &&
        this.dataSource.length >= 10
      ) {
        this.allDataLoaded = true;
      }
      this.loading = false;
    },
    onClear() {
      // 这里是你希望在点击清除图标后执行的代码
      console.log("清除图标被点击");
      // 重新处理调用接口
      this.dataSource = [];
      this.allDataLoaded = false;
      // this.accountId = value.id
      this.pageStart = 1;
      setTimeout(() => {
        this.getSuperFileListFn();
      }, 100);
    },
    onSearch(e) {
      let value = e.target._value;
      //   this.dataSource = [];
      this.allDataLoaded = false;
      // this.accountId = value.id
      //   this.pageStart = 1
      setTimeout(() => {
        this.getSuperFileListFn(value);
      }, 100);
    },
    // 下载超大附件
    download(item) {
      console.log("附件id", item.id);
      downloadDocFile(item.id);
    },
    // 保存
    saveToDoc(value) {
      console.log("🚀 ~ file: index.vue:303 ~ saveToDoc ~ value:", value);

      if (this.token) {
        this.saveModalShow = true;
        this.saveOptions.checkIdList = [
          {
            sourceIds: [value.id],
          },
        ];
      } else {
        this.$message.warning("保存文件需要登录,请登录后保存");

        let shareKey = new URL(window.location.href).searchParams.get(
          "shareKey"
        );
        const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${shareKey}`;
        console.log("新的newUrl", newUrl);
        setTimeout(() => {
          window.location.href = newUrl;
        }, 2500);
      }
    },
    saveOk(targetId, sourceIds, teamId) {
      this.$refs.SaveModalRef.loading = true;
      let query = {
        targetId,
        ...sourceIds[0],
      };
      if (teamId) {
        query.teamId = teamId;
        this.saveToTeam(query);
      } else {
        this.saveToPersonDoc(query);
      }

      console.log("query", query);
    },
    // 保存到个人
    async saveToPersonDoc(params) {
      try {
        const res = await this.$apis.userFileCopy(params);
        this.$message.success("保存成功");
        this.saveModalShow = false;
      } catch (error) {
        console.log("error", error);
      }
    },
    // 保存到企业团队
    async saveToTeam(params) {
      try {
        const res = await this.$apis.companyFileCopy(params);
        this.$message.success("保存成功");
        this.saveModalShow = false;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.supercolossal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .loading-spinner-box {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    color: #3271ff;
    font-weight: 600;
    .loading-spinner-ing {
      margin-top: 0 !important;
      margin-bottom: 8px;
    }
  }
  .table-container-attachment {
    padding-top: 30px;
  }
}
</style>
