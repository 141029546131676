
// 纯展示用组件, 不处理任何逻辑
import Vue from 'vue'
import { Component, Prop, Watch, Ref, Inject } from 'vue-property-decorator'
import { Empty } from "ant-design-vue";
import dayjs from 'dayjs'
import { FileProps } from './interface'
@Component({
    components: { Empty },
    filters: {
        // 时间格式化
        timeFormat(time: string | number) {
            return dayjs(time).format('MM-DD HH:mm')
        },
    },
})
export default class ClassComponent extends Vue {
    // 文件列表
    @Prop({ type: Array, default: () => [] })
    listData!: any[]

    // 被选中文件id
    @Prop({ type: Array, default: () => [] })
    checkIdList!: any[]

    // @Inject() readonly vm!: any;

    // 判断文件是否被选中
    isChecked(el: FileProps) {
        // @ts-ignore
        // return el[this.vm.id] && this.checkIdList.includes(el[this.vm.id])
        return el.id && this.checkIdList.includes(el.id)
    }

    // 组件触发的所有事件直接外发, 父级决定TODO内容
    toCheckItem(el: FileProps, keyup: string) {
        this.$emit('checkItem', el, keyup)
    }
    toDblclickItem(el: FileProps) {
        this.$emit('dbCheckItem', el)
    }
    toHandleMousedown(value: any, keyup: string, e: any) {
        this.$emit('sendMousedown', value, keyup, e)
    }
    handleClick(el: any, item: any = {}) {
        this.$emit('menuClick', el, item)
    }
    clickContextMenu(e: any, el: any) {
        this.$emit('contextMenu', e, el)
    }
    unselect(el: any) {
        this.$emit('sendUnselect', el)
    }
}
