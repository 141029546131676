<template>
  <div class="attachment">
    <div class="attachment-table">

      <div class="mailAccount">

        <div class="adress_box">
          <div class="adress">
            <a-select v-if="mailAddressList.length && accountId.length" size="large" class="adress_select custom-select"
              v-model="accountId">
              <a-icon slot="suffixIcon" type="caret-down" />
              <template #selectedItemTemplate="{ value }">
              </template>
              <a-select-option class="adress_select_option" dropdownClassName="my-custom-dropdown" :value="item.id"
                v-for="(item) in mailAddressList" :key="item.id" @click="handleChange(item)">
                <div class="adress_select_option_firstPart" :style="getOptionStyle(item.color)">{{ item.firstPart }}
                </div>
                <span class="adress_select_option_secondPart">{{ item.secondPart }}</span>
                <span class="adress_select_option_hirdPart">{{ item.thirdPart }}</span>
              </a-select-option>
            </a-select>

            <a-input v-show="!noAccountIdFlag" :disabled="true" v-else />
          </div>
        </div>

        <div class="iptBox">
          <!-- <a-input-search  size="large"  prefix placeholder="input search text" style="width: 200px" @search="onSearch" />
             -->
          <a-input  v-if="mailAddressList.length && accountId.length" ref="userNameInput" class="input-class-left" size="large" style="width: 240px" v-model="inputValue"
            allow-clear placeholder="搜索" allowClear @pressEnter="onSearch">
            <a-icon slot="prefix" type="search" />
          </a-input>
        </div>


      </div>

      <div class="table-container-attachment">
        <a-spin :spinning="loading">
          <a-table :dataSource="dataSource" ref="attachmentTableRefs" :columns="columns" :pagination="false"
          :scroll="{ y: fileListEleMaxHeight || 550 }">

            <template slot="name" slot-scope="text, record">
              <template>
                <h-icon class="list-img" :type="getFileType(record.fileName)" alt="" />
                <span class="name-text click">{{ text }}</span>
                <a-icon v-if="record.isCollected" type="star" theme="filled" class="favorite" />
              </template>
            </template>

            <template slot="action" slot-scope="text, record">
              <a-dropdown>
                <a><a-icon type="dash" /></a>
                <a-menu slot="overlay">
                  <a-menu-item @click="send(record)">
                    <a>邮件发送</a>
                  </a-menu-item>
                  <a-menu-item @click="download(record)">
                    <a>下载</a>
                  </a-menu-item>
                  <a-menu-item @click="saveToDoc(record)">
                    <a>转存到个人文档</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </a-table>
        </a-spin>
        <div class="loading-spinner-box">
          <div v-if="loading && dataSource.length" class="loading-spinner-ing">加载中...</div>
          <div v-if="showAllDataLoadedMessage" class="loading-spinner-end">已加载完全部数据</div>
        </div>
      </div>

      <!-- <fileModal v-if="fileModalShow" :visible="fileModalShow" type="save" :options="saveOptions"
        @close="fileModalShow = false" @save="saveOk" /> -->

      <!-- 保存确认 -->
      <SaveModal ref="SaveModalRef" v-if="saveModalShow" :visible="saveModalShow" type="save" :options="saveOptions"
        @close="saveModalShow = false" @save="saveOk" />

    </div>
  </div>
</template>

<script >
import { creatClientId } from "@/common/utils/util";

import SaveModal from "@/views/popup/moveModal.vue";
import fileModal from "./fileModal";
export default {
  name: 'Attachment',
  components: {
    SaveModal,
    fileModal
  },
  data() {
    return {
      fileListEleMaxHeight:550,
      inputValue: '',
      keyword: '',
      md5: '',
      colors: ['#15BE87', '#1890FF', '#FF943E', '#7750EB', '#FF6546'],  // 你的五个颜色值  
      lastColor: null, // 上一次选择的颜色  
      showAllDataLoadedMessage: false,
      allDataLoaded: false, // 标志是否全部加载完
      page: 1, // 当前页数  
      paginationConfig: {
        current: 1, // 当前页码
        pageSize: 10, // 每页显示条数
        total: 0, // 总条数
        showQuickJumper: true, // 是否显示快速跳转输入框
        onChange: this.handlePageChange, // 页码改变时的回调函数
        onShowSizeChange: this.handlePageSizeChange // 每页显示条数改变时的回调函数
      },
      noAccountIdFlag: false,
      // accountId: '1722879860549881857',
      accountId: '',
      defaultValue: null,
      mailAddressList: [
        // {
        //   id: "1722879860549881857",
        //   account: "chencanpeng@lgm.csiiclouds.com",
        //   address: "chencanpeng@lgm.csiiclouds.com",
        //   connectionName: "CSIISZ",
        //   lastReceiveTime: null,
        //   mailType: 0,
        //   count: null,
        //   status: 1,
        //   nickName: "陈灿鹏",
        //   color: "#1890ff",
        //   maxSize: 3221225472,
        //   usedSize: 84088589,
        //   bigAttachmentSize: 1073741824
        // }
      ],
      fileModalShow: false,
      saveOptions: {
        checkIdList: []
      },
      pageStart: 1,  // 当前偏移量  
      pageSize: 10,
      loading: false, // 是否正在加载数据  
      saveModalShow: false,
      title: '邮件往来附件',
      dataSource: [], // 已加载的数据  
      columns: [
        {
          width: 380,
          title: '名称',
          dataIndex: 'fileName',
          // key: 'fileName',
          align: "left",
          scopedSlots: { customRender: "name" },
        },
        {
          title: '大小',
          dataIndex: 'attachmentValue',
          align: "center",
          key: 'attachmentValue',
        },
        {
          title: '时间',
          dataIndex: 'saveDate',
          align: "center",
          key: 'saveDate',
        },
        {
          title: '来源',
          dataIndex: 'subject',
          align: "center",
          key: 'subject',
        },
        {
          title: "操作",
          dataIndex: "",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],

    }
  },

  watch: {
    inputValue(newVal) {
      console.log("搜索输入关键词:", newVal)
      if (!newVal.length || newVal == '') {
        this.onClear()
      }
    }
  },

  mounted() {

    // console.log('openOrNotStarDocFlag',this.openOrNotStarDocFlag);

    const tableBody = this.$refs.attachmentTableRefs.$el.querySelector('.ant-table-body');
    tableBody.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    // openOrNotStarDocFlag() {
    //   return this.$store.state.user.openOrNotStarDocFlag
    // },
    selectedItem() {
      return this.mailAddressList.find(obj => obj.id == this.accountId);
    },

    // 租户id 企业id
    companyId() {
      return this.$store.state.user.companyId
    },
    token() {
      return this.$store.state.user.token
    }
  },
  async created() {


    try {
      await this.getMailAddressList();
      if (this.noAccountIdFlag) {
        return
      }
      // 按顺序执行第二个方法
      await this.getAttachmentList()
    } catch (error) {
      console.error(error);
    }
  },

  methods: {


    onClear() {
      // 这里是你希望在点击清除图标后执行的代码  
      console.log('清除图标被点击');
      // 重新处理调用接口  
      this.dataSource = []
      this.allDataLoaded = false
      // this.accountId = value.id
      this.pageStart = 1
      setTimeout(() => {
        this.getAttachmentList()
      }, 100);
    },
    onSearch(e) {
      let value = e.target._value
      this.dataSource = []
      this.allDataLoaded = false
      // this.accountId = value.id
      this.pageStart = 1
      setTimeout(() => {
        this.getAttachmentList(value)
      }, 100);
    },

    getOptionStyle(color) {
      return {
        color: color,
        border: `1px solid ${color}`,
      }
    },


    getFileType(fileName) {
      if (fileName) {
        return fileName.split(".").pop();
      } else {
        return 'undefind'; // 默认的类型，你需要根据实际情况设定  
      }
    },
    handleScroll(event) {
      // alert('999')
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollHeight - scrollTop === clientHeight) {
        if (!this.allDataLoaded) {
          this.getAttachmentList();  // 当滚动到底部时加载下一页数据  
        } else {
          this.showAllDataLoadedMessage = true; // 显示“已加载完全部数据”消息  
          setTimeout(() => {
            this.showAllDataLoadedMessage = false; // 2秒后隐藏该消息  
          }, 1000);
        }
      }
    },


    async getAttachmentList(val) {
      this.loading = true;
      let params = {
        pageSize: this.pageSize,
        pageStart: this.pageStart,
        accountId: this.accountId
      };
      let header = {
        Accountid: this.accountId
      }
      if (val && val.length) {
        params.keyword = val
      }

      try {
        const res = await this.$apis.userAttachmentFiles(params, header);
        // this.loading = false;
        if (res && res.result && res.result.length > 0) {
          // this.dataSource = res.result
          if (Array.isArray(res.result)) {
            // 遍历 res.result 数组，将每个对象 push 到 this.dataSource 中  
            res.result.forEach(item => {
              this.dataSource.push(item);
            });
          }
          this.pageStart++;  // 增加页码
          this.loading = false;
        } else {
          // 如果 res.result 为空数组或者不存在数据，说明数据全部加载完毕，终止请求  
          this.loading = false;
          // 可以设置一个标志位表示数据已经全部加载完，后续就不再发送请求  
          this.allDataLoaded = true;

        }
        console.log("🚀邮件往来附件 ~ res:", this.dataSource);
      } catch (error) {
        this.loading = false;
        console.error("Error fetching attachment list:", error);
      }
    },

    handlePageChange(page) {
      // 处理页码改变事件

      // 发起异步请求获取对应页码的数据

      // 更新 dataSource 和 paginationConfig 的值

    },

    async getMailAddressList() {
      try {

        const res = await this.$apis.getMailAddress();
        console.log('邮箱数据res', res);
        if (res && res.result && res.result.length) {
          this.mailAddressList = res.result.map(obj => {
            let email = obj.address; // 假设邮箱地址存储在email字段中  
            // 使用正则表达式匹配邮箱的各个部分  
            let matches = email.match(/^(.)(.*)@(.*)$/);
            // 在当前对象上添加三个新的字段  
            obj.firstPart = matches[1].toUpperCase(); // 首字母大写  
            obj.secondPart = matches[1] + matches[2];              // @前面的部分  
            obj.thirdPart = `<${matches[3]}>`;        // @后面的部分，并用尖括号括起来  
            return obj; // 返回修改后的对象  
          });



          const colors = ['#15BE87', '#1890FF', '#FF943E', '#7750EB', '#FF6546'];
          let previousColors = [];

          function assignRandomColor(mailAddressListItem) {
            let randomIndex = Math.floor(Math.random() * colors.length);

            // 如果previousColors中已经包含了所有颜色，重置previousColors数组  
            if (previousColors.length === colors.length) {
              previousColors = [];
              randomIndex = Math.floor(Math.random() * colors.length);
            }

            // 如果随机到的颜色在previousColors中已经存在，那么我们重新随机    
            while (previousColors.includes(colors[randomIndex])) {
              randomIndex = Math.floor(Math.random() * colors.length);
            }

            // 将随机到的颜色分配给当前对象，并添加到previousColors中    
            const color = colors[randomIndex];
            mailAddressListItem.color = color;
            previousColors.push(color);

            return mailAddressListItem; // 返回修改后的对象    
          }

          // this.mailAddressList = this.mailAddressList.map(item => assignRandomColor({ ...item }));
          this.mailAddressList = this.mailAddressList.map((item, index) => {
            // 当index大于0且index可以被colors长度整除时，清空previousColors数组  
            if (index > 0 && index % colors.length === 0) {
              previousColors = [];
            }
            return assignRandomColor({ ...item });
          });


          console.log('邮箱号列表', this.mailAddressList);

          if (this.mailAddressList.length) {
            this.defaultValue = this.mailAddressList[0].id;
            this.accountId = this.mailAddressList[0].id;
          }
        } else {
          this.noAccountIdFlag = true
        }

      } catch (err) {
        console.log('err', err);
        this.noAccountIdFlag = true
      }
    },

    handleChange(value) {
      console.log('value是啥', value);

      this.dataSource = []
      this.allDataLoaded = false
      this.accountId = value.id
      this.pageStart = 1

      // return  

      setTimeout(() => {
        this.getAttachmentList()
      }, 300);


    },
    async send(value) {
      console.log("🚀 ~ file: index.vue:84 ~ handleViewShare ~ val:", value)
      if (this.token) {


        this.fileModalShow = true;
        this.saveOptions.checkIdList = [value.userFileId];
        console.log("🚀 ~ file: 选择了哪些文件:", this.saveOptions.checkIdList)
        let query = {
          // folder: value.folder,
          // id: value.uid,
          // partId: value.partId,
          blobId: value.blobId,
          fileName:value.fileName
        }
        let header = {
          Accountid: this.accountId
        }
        try {
          const res = await this.$apis.getMailMD5(query, header);
          if (res) {
            this.md5 = res.message
            console.log("🚀 ~ file: index.vue:222 ~ send ~ res:", res)
            let TempObj = {
              fileName: value.fileName,
              attachmentValue: value.attachmentValue,
              md5: this.md5,
              mimeMessageID: value.mimeMessageID,
              accountId: this.accountId
            }
            window.sessionStorage.setItem('CloudFile', JSON.stringify(TempObj))
            let origin = window.location.origin
            let url = `${origin}/mail/home?from=files`
            // window.open('')
            console.log("🚀 ~ file: index.vue:240 ~ send ~ url:", url)
            // window.location.href = url
            window.history.pushState({}, "", url);

          }
        } catch (err) {
          console.log(err);
        }

      }
    },
    download(value) {
      console.log("🚀 ~ file: index.vue:224 ~ download ~ value:", value)
      if (this.token) {
        let downloadLoading = this.$message.loading("下载中", 0)

        // new URLSearchParams()
        const params = {
          // download: true,
          // folder: value.folder,
          // id: value.uid,
          // partId: value.partId,
          // accountId: this.accountId,
          // TenantId: this.companyId,
          // mimeMessageID: value.mimeMessageID,
          blobId: value.blobId,
          fileName:value.fileName
        }
        let header = {
          Accountid: this.accountId
        }

        this.$apis.downloadAttachmentFiles(params, header).then(res => {
          if (!res) {
            this.$message.warning("下载失败");
            downloadLoading()
            return
          }
          if (res) {
            downloadLoading()
            const blob = new Blob([res], { type: 'application/octet-stream' });
            console.log("下载的文件名", value.fileName)
            const url = URL.createObjectURL(blob);
            console.log("下载的url url:", url)

            const link = document.createElement('a');
            link.href = url;
            link.download = value.fileName;
            link.click();
            URL.revokeObjectURL(url);
            // 下载完成后移除 <a> 标签
            link.remove();
          }

        }).catch(err => {
          downloadLoading()
          console.log('下载失败', err);
        })


      }
      // this.$apis.downloadAttachmentFiles()


    },

    // 保存
    saveToDoc(value) {
      console.log("🚀 ~ file: index.vue:303 ~ saveToDoc ~ value:", value)



      if (this.token) {
        this.saveModalShow = true;
        this.saveOptions.checkIdList = [{
          // folder: value.folder,
          // id: value.uid,
          // partId: value.partId
          blobId: value.blobId,
          fileName:value.fileName

        }];
      } else {
        this.$message.warning("保存文件需要登录,请登录后保存");

        let shareKey = new URL(window.location.href).searchParams.get('shareKey');
        const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${shareKey}`;
        console.log('新的newUrl', newUrl);
        setTimeout(() => {
          window.location.href = newUrl
        }, 2500);
      }
    },
    saveOk(targetId, sourceIds, teamId) {
      this.$refs.SaveModalRef.loading = true
      let query = {
        targetId,
        ...sourceIds[0],
        teamId,
      }

      console.log('query', query);

      let header = {
        Accountid: this.accountId
      }

      this.$apis
        .AttachmentFilesCopy(
          query,
          header,
        )
        .then((res) => {
          if (res.code == 200) {
            this.$refs.SaveModalRef.loading = false
            this.$message.success("文件保存成功");
            this.saveModalShow = false;

          }
        }).catch(err => {
          console.log('err', err);
          this.saveModalShow = false;
        })
    },



  }
}
</script>




<style lang="less" scoped>
.attachment {
  width: 100%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;

  .loading-spinner-box {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    color: #3271ff;
    font-weight: 600;
    .loading-spinner-ing {
      margin-top: 0 !important;
      margin-bottom: 8px;
    }
  }


  // 邮箱选择
  .mailAccount {
    // margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;



    .adress-title {
      font-size: 16px;
      margin-left: 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .adress_box {
      width: 312px;
    }

    .iptBox {
      // margin-right: 15px;
      // margin-left: auto;
      margin-left: 16px;


      // margin-left: 30px;


    }

    .adress {
      height: 100%;
      display: flex;
      align-items: center;

      // justify-content: center;
      .adress_select {
        width: 100%;
        // box-sizing: content-box;
        // padding-left: 4px;


      }
    }
  }

  .title {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333333;
    font-family: PingFangSC;
    font-weight: 500;
    padding-left: 4px;
  }

  .attachment-table {
    // padding: 20px;
    padding-top: 20px;

    .table-container-attachment {
      margin-top: 10px;
      .list-img {
        height: 24px;
        margin-right: 8px;
      }
    }
  }
}
</style>


<style lang="less">
.input-class-left {
  .ant-input {
    text-align: left !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #111111 !important;
    border: 1px solid #cccccc !important;
    border-radius: 4px !important;
  }
}


.adress .ant-select-selection-selected-value {
  display: flex !important;
  align-items: center;

  .adress_select_option_firstPart {
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-size: 12px !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 12px !important;
    font-weight: 400;
    // color: #7750EB !important;
    // border: 1px solid #7750EB !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .adress_select_option_secondPart {
    margin: 0 8px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-size: 16px !important;
    font-weight: 500;
    color: #333333;

  }

  .adress_select_option_hirdPart {
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-size: 12px;
    font-weight: 400;
    color: #999999;

  }
}


// 下拉框选项样式，这个影响全局了，不过下拉选项给了一定的padding，ui上是美观的，后期如需要再添加自定义类名缩小修改反问

.ant-select-dropdown {
  padding: 2px !important;
  border-radius: 4px !important;
}

.ant-select-dropdown-menu {
  padding-left: 0px !important;
  padding: 1px !important;
}

.adress_select_option.ant-select-dropdown-menu-item {
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 4px !important;

}

.adress_select_option.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: rgba(50, 113, 255, 0.1) !important;
  border-radius: 4px !important;
  // padding: 2px !important;
}


.adress_select_option {

  display: flex;

  .adress_select_option_firstPart {
    // color: #7750EB !important; 
    // border: 1px solid #7750EB !important;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-size: 12px !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 12px !important;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -2px;
  }

  .adress_select_option_secondPart {
    margin: 0 8px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-size: 16px !important;
    font-weight: 500;
    color: #333333;
  }

  .adress_select_option_hirdPart {
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }

}






.custom-select .ant-select-selection {
  /* 或者设置为与背景色相同的颜色 */
  border-color: #ccc !important;
  border-radius: 4px !important;
}


/* 当具有 .custom-select 类名的下拉框聚焦时，移除蓝色外框并设置阴影 */
.custom-select.ant-select-focused .ant-select-selection,
.custom-select .ant-select-selection:focus,
.custom-select .ant-select-selection:active {
  border-color: #ccc !important;
  /* 或者你想要的其他颜色 */
  box-shadow: 0 0 0 1px rgba(228, 231, 237, 0.5) !important;
  /* 设置阴影颜色和大小 */
}



// /* 如果你希望聚焦时也没有蓝色外框，可以添加以下样式 */
// .ant-select-focused .ant-select-selection,
// .ant-select-selection:focus,
// .ant-select-selection:active {
//   border-color: #ccc !important;
//   /* 或者设置为与背景色相同的颜色 */
//   box-shadow: 0 0 0 1px rgba(228, 231, 237, 0.5) !important;

//   /* 移除聚焦时的阴影 */
// }
</style>