<template>
  <a-modal class="move-mainmenu" :visible="visible" :confirm-loading="false" :width="700" :centered="true" destroyOnClose
    @cancel="onClickCancle">

    <!-- 左侧菜单栏(个人文档/企业团队/邮件往来附件) -->
    <div class="move-leftmenu">
      <div class="move-leftmenu-title">从AI文档添加</div>
      <ul class="move-leftmenu-list">
        <li :class="item.title == selMenuPath ? 'move-leftmenu-list-action' : ''" v-for="item in menuList"
          :key="item.title">
          <div class="list-wrap" :class="(item.title == '企业团队' || item.title == '邮件往来附件') ? 'disabled-box' : ''"
            @click="leftMenuClick(item)">
            <h-icon :iconName="item.icon" className="menu-icon" />
            <span>{{ item.title }}</span>
          </div>
          <ul class="team-list" v-if="item.teamList">
            <a-spin :spinning="loadingTeam">
              <li :class="{ 'team-actived': teamId.includes(team.id) && modifiedModuleType == 'team' }"
                v-for="team in item.teamList" :key="team.id" @click="selectTeam(team)">
                <h-icon iconName="team" className="menu-icon" />
                <span>{{ team.teamName }}</span>
              </li>
            </a-spin>
          </ul>


          <ul class="team-list" v-if="item.mailAddressList">
            <li :class="{ 'team-actived': addressId.includes(i.id) && modifiedModuleType == 'acctachment' }"
              v-for="i in item.mailAddressList" :key="i.id" @click="selectAddress(i)">
              <a-tooltip placement="bottom" :title="i.address">
                <h-icon iconName="team" className="menu-icon" />
                <span>{{ i.address }}</span>
              </a-tooltip>
            </li>
          </ul>

        </li>



      </ul>
    </div>


    <div class="move-mainmenu-title" slot="title">
      <!--  -->
      <div class="star-header-top" v-show="breadcrumbList.length > 1">
        <!-- 面包屑, 根目录不展示 -->
        <a-breadcrumb class="star-header-top-breadcrumb" separator="">
          <!-- 面包屑前面加逐级返回选项 -->
          <a-breadcrumb-item class="breadcrumb-back" style="cursor: pointer"><span @click="toPreLevel">返回上一级</span>
            <span class="star-header-top-breadcrumb-separator">|</span>
          </a-breadcrumb-item>
          <!-- 面包屑真实路径 -->
          <a-breadcrumb-item style="cursor: pointer" v-for="(el, i) in breadcrumbList" :key="el.id"
            class="hover-effects"><span @click="jumpToFolder(el, i)">{{ el.text }}</span>
            <!-- 面包屑结尾加斜线标志, separator自维护 -->
            <span class="star-header-top-breadcrumb-separator">/</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

    </div>
    <a-spin :spinning="loading">
      <div class="move-mainmenu-content" v-if="listData.length > 0">
        <!-- 总览, 全选 -->
        <!-- <a-radio :checked="checkAll" @click="onCheckAll">
          共{{ listData.length }}项
        </a-radio> -->
        <!-- 文件列表 -->
        <div class="star-content-fileContainer" ref="starContent">
          <a-spin :spinning="wrapLoading">
            <!-- @checkListItem 选中文件改变时触发, 返回所有文件id -->
            <!-- 展示平铺文件夹形式 -->
            <!-- @checkItem单击文件 / @dbCheckItem双击文件  / -->
            <TileList :checkIdList="checkIdList" :listData="listData" @checkItem="toCheckItem"
              @dbCheckItem="toDblclickItem" @sendUnselect="handleUnselect"
              @contextMenu="(e, el) => onContextMenu(e, 'fileContextContent', el)" />
          </a-spin>
        </div>
      </div>
      <div class="move-mainmenu-empty" v-else>暂无数据</div>
    </a-spin>




    <!-- 底部 -->
    <div class="move-mainmenu-footer" slot="footer">
      <div class="move-mainmenu-footer-btnbox">
        <a-button @click="onClickCancle">取消</a-button>
        <a-button type="primary" @click="fileSave">添加<span v-if="checkIdList.length">({{ checkIdList.length
        }})</span></a-button>
      </div>
    </div>

  </a-modal>
</template>

<script>
import TileList from "./tileList";
// import { namespace } from "vuex-class";
// const TeamModule = namespace("team");


export default {
  name: 'fileModal',
  components: {
    TileList
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: {},
    },
    moduleType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      accountId: '',
      isDirid: '',
      tabIndex: 0,
      wrapLoading: false,
      checkIdList: [],
      filterCheckIdList: [],
      checkAll: false,
      teamFlag: false,
      teamList: [],
      title: "",
      selItem: {},
      loading: false,
      loadingTeam: false,
      selMenuPath: "",
      listData: [],
      rightMenuSelIndex: NaN,
      breadcrumbList: [],
      menuList: [
        {
          title: "个人文档",
          icon: "document",
          action: false,
          path: "/star/mydoc",
          routeNmae: "VIEW_MY_DOCUMENT",
          moduleType: "doc",
        },
        {
          title: "企业团队",
          icon: "menuTeam",
          action: false,
          path: "/*",
          routeNmae: "",
          moduleType: "team",
          teamList: [],
        },
        {
          title: "邮件往来附件",
          icon: "document",
          action: false,
          path: "/*",
          routeNmae: "",
          moduleType: "acctachment",
          mailAddressList: []
        },
      ],
      addressId: '',
      teamId: "",

    }
  },
  computed: {

    // // 面包屑根路径
    // breadcrumbList() {

    // },

    // 租户id 企业id
    companyId() {
      return this.$store.state.user.companyId
    },
    token() {
      return this.$store.state.user.token
    },
    // teamList() {
    //   return this.$store.state.team.teamList
    // }
  },
  watch: {
    checkIdList: {
      handler(val) {
        console.log('this.listData', this.listData);
        // 过滤掉文件夹的情况
        if (!val) {
          return
        }
        // 维护全选状态标记
        this.checkAll = val.length === this.listData.filter(el => el.isDir !== 1).length;
      },
      deep: true,
    },
    isDirid: {
      handler() {
        // 防止所在其他标签下点击个人文档标签不切换
        // this.tabIndex = 0
        this.dataInit();
      },
      deep: true,
    },

  },
  created() {

    this.getMailAddressList();
    if (!this.teamList.length) {
      this.$apis.companyTeamPageList({
        companyId: this.companyId,
        isPage: 0,
      }).then((res) => {
        if (res.code === '200') {
          this.teamList = res.data.list
          this.teamList.sort((a, b) => a.isStaff === 1 ? -1 : b.isStaff === 1 ? 1 : 0)
          // this.$store.commit('team/SET_TEAMLIST', res.data.list)
          this.menuList[1].teamList = this.teamList
        }
      })
    }

    this.dataInit()





  },
  mounted() {

    this.leftMenuClick(this.menuList[0]);
  },

  methods: {

    dataInit() {
      let id = this.isDirid || "";

      // this.fetchData(id).then(() => {
      //   // 如果有传fileId, 默认选中
      //   let fileId = this.$route.query.fileId;
      //   this.checkIdList = fileId ? [fileId] : [];
      // });
      // 获取面包屑
      this.setBreadcrumb(id);
    },

    // 获取邮箱账号
    async getMailAddressList() {
      try {
        const res = await this.$apis.getMailAddress();
        if (res) {
          this.menuList[2].mailAddressList = res.result;
          console.log("🚀 ~ file: fileModal.vue:269 ~ getMailAddressList ~ this.mailAddressList:", this.mailAddressList)

        }
      } catch (err) {
        console.log(err);
      }
    },

    async setBreadcrumb(id, breadcrumb) {

      // this.breadcrumbList=  this.modifiedModuleType=="team"

      let breadcruunbListInit = []
      if (this.modifiedModuleType === 'team') {
        let teamName = this.teamList.find(obj => obj.id === this.teamId).teamName;
        breadcruunbListInit = [{
          id: '',
          text: teamName
        }];
      } else {
        breadcruunbListInit = [{
          id: '',
          text: '个人文档'
        }];
      }

      if (id) {
        // console.log('002--');

        // const pathDicApi = this.modifiedModuleType === 'team' ? this.$apis.companyFileQueryPathDic : this.$apis.queryPathDic
        let params = {
          id,
        }
        let apiName = 'queryPathDic'
        if (this.modifiedModuleType === 'team') {
          params = {
            id,
            teamId: this.teamId
          }
          apiName = 'companyFileQueryPathDic'
        }
        console.log('params', params);
        console.log('apiName', apiName);

        const res = await this.$apis[apiName](params)
        console.log('-----res=-====', res);
        console.log('id', id);
        let resBreadcrumbList = res.data
          // 数据过滤清洗
          .filter((el) => el.dirName)
          .map((el) => {
            return {
              id: el.pathFileId,
              text: el.dirName,
            };
          });
        this.breadcrumbList = breadcruunbListInit.concat(resBreadcrumbList);

      } else {
        console.log('没有id的情况处理 ', this.breadcrumbList);
        this.breadcrumbList = [...this.breadcrumbList];

        if (this.modifiedModuleType === 'team') {
          let teamName = this.teamList.find(obj => obj.id === this.teamId).teamName;
          this.breadcrumbList = [{
            id: '',
            text: teamName
          }];
        } else {
          this.breadcrumbList = [{
            id: '',
            text: '个人文档'
          }];
        }




      }
    },

    // 点击右上角小√取消选择
    handleUnselect(value) {
      this.checkIdList.splice(
        this.checkIdList.findIndex((item) => item === value.id),
        1
      );
    },

    // 点击面包屑跳转到指定文件夹
    jumpToFolder(el, i) {


      // 最后一项不让点击
      if (i < this.breadcrumbList.length - 1) {
        // const query = {
        //   id: el[this.id],
        //   teamId: this.$route.query.teamId
        // }
        // this.$router.push({ name: this.routeName, query });

        this.getFileList(el.id);
        this.isDirid = el.id


        console.log('点了什么id', el.id);
        console.log('点了什么id', this.isDirid);
      }
    },

    // 双击文件/文件夹
    toDblclickItem(el) {
      console.log('是什么类型', el);
      let compressedFilesList = ['zip', 'rar', '7z', 'tar', 'gz', 'bz2', 'xz']
      // 如果是压缩文件 只支持zip解压
      if (compressedFilesList.includes(el.extensionName)) {
        return
      }
      // 如果是文件夹，需要处理面包屑和路径展示问题
      else if (parseInt(el.isDir) === 1) {

        // 不能跳路由，这里等会单独做处理
        // let query = {
        //   id: el[this.id],
        //   teamId: el.teamId || this.$route.query.teamId
        // }
        // this.$router.push({ name: this.routeName, query });


        this.isDirid = el.id

        // console.log('面包屑', this.breadcrumbList);

        this.getFileList(el.id);
        this.selItem = el;
      }
    },

    // 选中/取消选中文件
    toCheckItem(el, keyup, isMove) {

      //  如果是文件夹，不允许选择
      if (el.isDir == 1) {
        return
      }
      let arr = this.checkIdList;
      if (keyup === 'ctrl') {
        if (arr.includes(el.id)) {
          // 如果不是文件移动取消选择
          if (!isMove) {
            arr.splice(
              arr.findIndex((item) => item === el.id),
              1
            );
          }
        } else {
          arr.push(el.id);
        }
      } else if (keyup === 'shift') {
        // debugger
        //  如果是文件夹，不允许选择
        if (el.isDir == 1) {
          return
        }
        if (this.checkIdList.length <= 0) {
          //  如果是文件夹，不允许选择
          if (el.isDir == 1) {
            return
          }
          this.checkIdList = [el.id]
        } else {
          //  如果是文件夹，不允许选择
          if (el.isDir == 1) {
            return
          }
          let targetIndex = this.listData.findIndex(i => i.id === el.id)
          // 获取选中的文件中索引最靠前的
          let startIndex = arr.reduce((total, value) => {
            let index = this.listData.findIndex(i => i.id === value)
            if (total === undefined || total > index) {
              return index
            } else {
              return total
            }
          }, undefined)
          // 获取选中的文件中索引最靠后的
          let endIndex = arr.reduce((total, value) => {
            let index = this.listData.findIndex(i => i.id === value)
            if (total === undefined || total < index) {
              return index
            } else {
              return total
            }
          }, undefined)
          // console.log(startIndex, endIndex, targetIndex)
          // 索引范围
          let rangeIndex
          // 当选中文件仅为1个时,根据点击的目标文件确定范围
          if (startIndex === endIndex) {
            rangeIndex = startIndex
            this.getCheckIdList(targetIndex, rangeIndex)
          } else if ((targetIndex - startIndex) < (endIndex - targetIndex)) {
            rangeIndex = endIndex
            this.getCheckIdList(targetIndex, rangeIndex)
          } else {
            rangeIndex = startIndex
            this.getCheckIdList(targetIndex, rangeIndex)
          }
        }
      } else {
        // 如果不是文件移动取消选择
        if (arr.includes(el.id) && arr.length === 1 && !isMove) {
          arr.splice(
            arr.findIndex((item) => item === el.id),
            1
          );
        } else {
          this.checkIdList = [el.id]
        }
      }

    },

    // 按住shift选择文件
    getCheckIdList(targetIndex, rangeIndex) {
      if (targetIndex > rangeIndex) {

        // this.checkIdList = this.listData.slice(rangeIndex, targetIndex + 1).map(i => i.id)

        // 过滤掉文件夹
        this.checkIdList = this.listData
          .slice(rangeIndex, targetIndex + 1)
          .filter(i => i.isDir !== 1)
          .map(i => i.id);


      } else if (targetIndex < rangeIndex) {

        // this.checkIdList = this.listData.slice(targetIndex, rangeIndex + 1).map(i => i.id)
          // 过滤掉文件夹
        this.checkIdList = this.listData
          .slice(targetIndex, rangeIndex + 1)
          .filter(i => i.isDir !== 1)
          .map(i => i.id);


      }
    },

    // 全选/清空
    onCheckAll() {


      if (this.checkAll) {
        this.checkIdList = [];
      } else {

        // 过滤掉文件夹的情况
        this.checkIdList = this.listData
          .filter(el => el.isDir !== 1)
          .map(el => el.id);

        console.log('  --this.checkIdList', this.checkIdList);
        console.log('---  this.listData', this.listData);
      }
    },

    // 返回上一级
    toPreLevel() {
      if (this.breadcrumbList.length > 1) {
        let el = this.breadcrumbList[this.breadcrumbList.length - 2];
        console.log('返回上一级的el', el);
        this.isDirid = el.id;
        console.log('返回到根目录了应该是', this.isDirid);
        // 不能跳转路由，在邮件的当前界面弹窗，路由跳转的话，选择完了又要跳回去,界面加载会非常慢
        // const query = {
        //   id: el[this.id],
        //   teamId: this.$route.query.teamId
        // }
        // this.$router.push({ name: this.routeName, query });


        this.getFileList(el.id);



      }
    },

    dbClickDir(item) {
      if (item != this.breadcrumbList.slice(-1)[0]) {
        this.breadcrumbList.push(item);

        console.log('dbClickDir----this.breadcrumbList', this.breadcrumbList);

      }
      // this.listData(item.id);
      this.selItem = item;
    },


    rightMenuClick(item, index) {
      console.log('.item', item);
      if (item == this.selItem) {
        this.rightMenuSelIndex = NaN;
        this.selItem = {};
      } else {
        this.rightMenuSelIndex = index;
        this.selItem = item;
      }
    },

    // 文件保存
    fileSave() {
      this.$emit("save", this.checkIdList, this.modifiedModuleType);
    },



    onClickCancle() {
      this.$emit("close", false);
    },


    // 获取文件列表
    async getFileList(id, selectId) {
      this.rightMenuSelIndex = NaN;
      this.listData = [];
      // this.loading = true;
      if (this.modifiedModuleType === "doc") {
        this.$apis.userFilePageList({
          id: id ? id : ""
        }).then(res => {
          console.log('个人文件列表res', res);
          this.listData = res.list
        })

        // await this.$apis
        //   .userFilePageList({ id: id ? id : "" })
        //   .then((res) => {
        //     res.list.forEach((element) => {
        //       // if (element.isDir == "1") {
        //       this.listData.push(element);
        //       // }
        //     });
        //   });
      } else if (this.modifiedModuleType === "team") {
        // 点到企业下团队
        await this.$apis
          .companyFilePageList({
            id: id ? id : "",
            teamId: this.teamId,
            isPage: 0,

          })
          .then((res) => {
            if (res && res.data && res.data.list) {
              this.listData = res.data.list
              console.log('企业团队文件列表', res.data.list);
            }
          });


      } else {
        // 邮件往来附件的
        try {
          this.listData = await this.getAttachmentList()
          console.log('邮件往来附件的-this.listData', this.listData);
        } catch (error) {
          console.log(error);
        }
      }
    },

    async getAttachmentList() {
      // 后续要让接口改成支持不分页
      let params = {
        pageSize: 10,
        pageStart: 1,
        accountId: this.addressId
      };

      try {
        const res = await this.$apis.userAttachmentFiles(params);
        if (res && res.result) {
          let resList = res.result
          console.log('resList', resList);
          const resListTemp = resList.map(obj => {
            return {
              userFileName: obj.fileName.split(".")[0],
              extensionName: obj.fileName.split(".")[1],
              // 默认写死为0  表示不是文件夹，邮件往来附件不可能是文件夹，为保证tileList字段逻辑统一加上
              isDir: 0,
              id: obj.uid,
              moduleType: obj.mediaType,
              updateTime: obj.saveDate
              // 添加其他属性...
            };
          });
          console.log("🚀 ~ file: fileModal.vue:647 ~ getAttachmentList ~ resListTemp:", resListTemp)
          return resListTemp;
        }

      } catch (error) {
      }
    },

    // 选择邮箱
    selectAddress(val) {
      console.log("🚀 ~ file: fileModal.vue:644 ~ selectAddress ~ val:", val)
      this.updateModuleType('acctachment')
      this.addressId = val.id
      this.selMenuPath = 'acctachment'
      this.selItem = {};
      this.getFileList();
    },

    // 选择团队
    selectTeam(team) {
      // this.moduleType='team'
      this.updateModuleType('team')
      this.selMenuPath = 'team'
      this.teamFlag = true
      this.teamId = team.id;
      let teamName = this.teamList.find(obj => obj.id === this.teamId).teamName;
      this.breadcrumbList = [{
        // id:this.teamI,
        id: '',
        text: teamName
      }];
      this.selItem = {};
      this.getFileList();
    },
    leftMenuClick(item) {
      this.updateModuleType(item.moduleType)
      if (this.modifiedModuleType == 'doc' || this.modifiedModuleType == 'acctachment') {
        this.selMenuPath = item.title;
        this.getFileList();
      }
      this.breadcrumbList = [];
      this.selItem = {};
    },
    updateModuleType(val) {
      this.modifiedModuleType = val;
    }

  }

}


</script>

<style scoped lang="less">
.move {
  &-leftmenu {
    position: absolute;
    width: 160px;
    height: 464px;
    background: #fafbfc;
    left: -160px;
    top: 0px;
    user-select: none;

    &-title {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 600;
      margin: 14px 0px 20px 20px;
    }

    ul {
      padding: 0px 12px;
      margin: 0px;
      list-style: none;
    }

    li {
      
      width: 136px;
      // height: 32px;
      line-height: 32px;
      text-align: left;
      font-size: 12px;

      .list-wrap {
        width: 100%;
        padding: 0 6px;
      }

      .menu-icon {
        width: 20px !important;
        height: 20px !important;
        // margin-bottom: 3px;
        margin-right: 8px;
        cursor: pointer;
      }

      span {
        cursor: pointer;
      }

      .team-list {
        padding: 0;

        li {
          display: flex;
      align-items: center;
          padding: 0px 12px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .team-actived {
          background-color: #eeeff3;
        }
      }
    }

    &-list {
      margin-top: 0px !important;

      &-action {
        background-color: #eeeff3;
      }
    }
  }

  &-mainmenu {
    user-select: none;

    &-back {
      display: flex;
      line-height: 18px;
      color: #4a61ff;

      &:hover {
        cursor: pointer;
      }

      >span {
        line-height: 18px;
        font-size: 14px;
        color: #e1e2e6;
        margin: 0px 4px;
      }
    }

    &-breadcrumb {
      line-height: 18px;

      a {
        color: #4a61ff;
      }
    }

    &-title {
      display: flex;
      height: 18px;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
      line-height: 18px;
    }

    &-content {
      width: 100%;
      padding: 10px;
      height: 363px;

      .move-mainmenu-content-row:hover {
        background-color: #eeeff3;
      }

      &-item {
        display: flex;
        height: 48px;
        line-height: 48px;
        padding-left: 24px;
        font-size: 12px;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }

      &-itemActive {
        display: flex;
        height: 48px;
        line-height: 48px;
        padding-left: 24px;
        font-size: 12px;
        align-items: center;
        background: #eeeff3;

        &:hover {
          cursor: pointer;
        }
      }

      &-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    &-empty {
      display: flex;
      width: 100%;
      height: 363px;
      background: #ffffff;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      color: #c9c9c9;
    }

    &-footer {
      display: flex;
      height: 29px;
      align-items: center;
      justify-content: space-between;

      .add-newfloder {
        display: flex;
        align-items: center;
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        color: #4a61ff;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 16px;
          height: 16px;
          line-height: 16px;
          font-size: 12px;
          color: #4a61ff;
          margin-right: 10px;
        }
      }
    }
  }
}



/deep/.ant-modal-close-x {
  height: 50px;
  line-height: 50px;
}

/deep/.ant-breadcrumb {
  color: #4a61ff;
}

/deep/.ant-breadcrumb-separator {
  margin: 0px 4px;
}

/deep/.ant-modal-body {
  padding: 0px;
  overflow-y: auto;
}

.disabled-box {
  pointer-events: none;
  // opacity: 0.5;
}

.move-mainmenu-footer {
  justify-content: right;
}
</style>