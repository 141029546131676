<template>
  <div class="attachment-all">
    <div class="title-top">
      {{ title }}
    </div>

    <div class="btn-box">
      <a-button @click="isActive = 'supercolossal'" :type="isActive == 'supercolossal' ? 'primary' : ''">
        超大附件
      </a-button>
     
      <a-button @click="isActive = 'normalSized'" :type="isActive == 'normalSized' ? 'primary' : ''">
        普通附件
      </a-button>
    </div>

    
    <div class="component-box">
      <component :is="isActive" />
    </div>

  </div>
</template>

<script>
import normalSized from './components/normalSized.vue'
import supercolossal from './components/supercolossal.vue'
export default {
  name: 'Attachment',
  components: {
    normalSized,
    supercolossal
  },
  data() {
    return {
      isActive: 'supercolossal',
      title: '邮件往来附件'

    }
  },

  watch: {

  },

  mounted() {

  },
  computed: {

  },
  async created() {

  },

  methods: {


  }
}
</script>


<style lang="less" scoped>
.attachment-all {
  box-sizing: border-box !important;
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  text-align: left;
  display: flex;
  flex-direction: column;

  .title-top {
    font-size: 18px;
    color: #333333;
    font-family: PingFangSC;
    font-weight: 500;
    padding-left: 4px;
    height: 32px;
  }

  .btn-box {
     height: 32px;
     margin-top: 18px;
  }



  .component-box {
    flex: 1;
    width: 100%;
    // padding: 20px;
    // background-color: rgba(0, 0, 0, .1);
  }
}
</style>
